import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

import Logo from '../../assets/images/logo.png'
import MapImg from '../../assets/images/map.png'
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="logo" />
                                </Link>
                            </div>
                            <p>FenuaHosting vous aide dans la réalisation de vos projets. Hébergez votre site internet, vos emails ou encore votre serveur de jeux !</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>FenuaHosting</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/hebergement-web">
                                        Hébergement Web &amp; Email
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/serveur-minecraft">
                                        Serveur Minecraft
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/serveurs-jeux">
                                        Autre jeux
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/a-propos">
                                        À propos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Information</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/faq">
                                        FAQ
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/protection-donnees-personnelles">
                                        protection des données personnelles
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/conditions-vente">
                                        Conditions de vente
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/mentions-legales">
                                        Mentions légales
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contactez-nous
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Support</h3>
                            
                            <ul className="footer-contact-info">
                                <li>
                                    <Icon.Mail />
                                    <a href="mailto:contact@fenuahosting.com" target="_top">contact@fenuahosting.com</a>
                                </li>
                                <li> 
                                    <Icon.PhoneCall />
                                    What's App: <a href="tel:+33695004694">06 95 00 46 94</a>
                                </li>
                            </ul>

                            <ul className="social-links">
                                <li>
                                    <a href="https://www.facebook.com/fenuahosting" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>
                                </li>
                                <li>
                                    <a href="https://discord.gg/dGscPuHNAS" className="facebook" target="_blank" rel="noreferrer"><Icon.MessageCircle /></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} FenuaHosting. All rights reserved by <a href="https://fenuahosting.com/" target="_blank">FenuaHosting</a></p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={MapImg} className="map" alt="map" />

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </footer>
    )
     
}

export default Footer; 