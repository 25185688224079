import React from "react";
import {Link} from 'gatsby';
import * as Icon from 'react-feather';

import Logo from "../../assets/images/logo.png"

const Navbar = () => {
    const [menu, setMenu] = React.useState(true)
 
    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0); 
    })
 
    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <header id="header" className="headroom">
            <div className="startp-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link to="/" onClick={toggleNavbar} className="navbar-brand">
                            <img src={Logo} alt="logo" />
                        </Link>

                        <button 
                            onClick={toggleNavbar} 
                            className={classTwo}
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link to="/" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Accueil
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/hebergement-web" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Web & Email
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="#" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Jeux <Icon.ChevronDown />
                                    </Link>

                                    <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link to="/serveur-minecraft" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Minecraft
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link to="/serveurs-jeux" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                                        Autres jeux
                                                    </Link>
                                                </li>
                                            </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="/a-propos" onClick={toggleNavbar} className="nav-link">
                                    À propos
                                    </Link> 
                                </li>
                            </ul>
                        </div>

                        <div className="others-option">

                            <Link to="/contact" className="btn btn-light">
							    Support
                            </Link>

                            <Link to="/dashboard/client/login/" className="btn btn-primary">
							    Se connecter
                            </Link>
						</div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;